// Here you can add other styles
.logoutButton {
  display: flex;
  align-items: center;
  padding: 0.8445rem 1rem;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  white-space: nowrap;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
}
